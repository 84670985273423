export default {
    data() {
        return {
            changeMultipleStatus: [],
            scrollPosition: null,
            showSideBar: true,
            activeName: "first",
            staffFullTab: "trial",
            appModal: false,
            form: {
                status_code: 0,
            },
            baseUrl: process.env.VUE_APP_URL.substring(
                0,
                process.env.VUE_APP_URL.length - 3
            ),
            drawerCreate: false,
            drawerShow: false,
            showDialog: false,
            drawerUpdate: false,
            filterForm: {},
            loadingData: false,
            excel_data: [],
            excel_fields: {},
            checkAll: false,
            selectedItems: [],
            selectedItem: null,
            reloadList: false,
            loadingComments: false,
            department: {
                company_id: null,
                branch_id: null,
                id: null
            },
            formStatus: {
                reason_id: null,
                staff_ids: []
            }
        };
    },
    watch: {
        filterForm: {
            handler: async function (newVal, oldVal) {
                await this.updatePagination({
                    key: "page",
                    value: 1
                });
                await this.updateFilter(newVal);
                this.debouncedFetchData();
            },
            deep: true,
            immediate: true,
        },
        sort: {
            handler: async function (newVal, oldVal) {
                if (newVal != oldVal && _.isFunction(this.debouncedFetchData)) {
                    this.debouncedFetchData();
                }
            },
            deep: true,
            immediate: true,
        },
        "pagination.page": {
            handler: async function (newVal, oldVal) {
                if (newVal != oldVal && _.isFunction(this.debouncedFetchData)) {
                    this.debouncedFetchData();
                }
            },
            deep: true,
            immediate: true,
        },
        "pagination.per_page": {
            handler: async function (newVal, oldVal) {
                if (newVal != oldVal && _.isFunction(this.debouncedFetchData)) {
                    this.debouncedFetchData();
                }
            },
            deep: true,
            immediate: true,
        },
        columns: {
            handler: function () {
                this.controlExcelData();
            },
            deep: true,
        },
        "filterForm.company_id": {
            handler: function (newValue, oldValue) {
                if (!newValue) {
                    this.$refs['sideBarDepartment'].department_ids = [];
                }
            }
        },
        department: {
            handler: function () {},
            deep: true,
            immediate: true
        }
    },
    methods: {
        refresh() {
            this.playRefreshSound();
            this.refreshData()
                .then((res) => {
                    this.$refs['sideBarDepartment'].department_ids = []
                    this.filterForm = JSON.parse(JSON.stringify(this.filter));
                    this.checkAll = false;
                    this.changeMultipleStatus = [];
                })
                .catch((err) => {});
        },
        playRefreshSound() {
            let audio = new Audio(
                "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
            );
            audio.play();
        },
        destroy(model) {
            this.delete(model.id)
                .then((res) => {
                    this.$alert(res);
                    this.fetchData();
                })
                .catch((err) => {
                    alert(err);
                });
        },
        print(model) {
            this.printModel({
                    id: model.id
                })
                .then((res) => {
                    const WinPrint = window.open(
                        "",
                        "",
                        "left=0,top=0,toolbar=0,scrollbars=0,status=0"
                    );
                    WinPrint.document.write(res.data);
                    WinPrint.document.close();
                    WinPrint.focus();
                    WinPrint.print();
                    WinPrint.close();
                })
                .catch((err) => {
                    this.$alert(err);
                });
        },

        profile(model) {
            let route = this.$router.resolve({
                name: "profile",
                params: {
                    id: model.id
                },
            });

            window.open(route.href, "_blank");

            //    OLD CODE
            //   this.$router.push({
            //     name: "profile",
            //     params: { id: model.id },
            //   });
        },
        edit(model) {
            this.selectedItem = model;
            this.drawerUpdate = true;
        },
        departmentSetter(value) {
            if (_.isObject(value)) {
                this.department = JSON.parse(JSON.stringify(value));
                console.log(this.department)
            }
        },
        handleCheckAllChange() {
            this.changeMultipleStatus = this.checkAll ?
                (this.changeMultipleStatus = this.list) : [];
            this.handleCheckedItemsChange();
        },
        handleCheckedItemsChange() {
            this.checkAll = this.changeMultipleStatus.length === this.list.length;
        },
        multiStatusChange() {
            this.formStatus.staff_ids = this.changeMultipleStatus.map((item) => {
                return item.id;
            });
            if (this.formStatus.staff_ids && this.formStatus.reason_id) {
                this.fireStaffs(this.formStatus)
                    .then((res) => {
                        this.$alert(res);
                        this.closeDialog();
                        this.fetchData();
                    })
                    .catch((err) => {
                        this.$alert(err);
                    });
            }


        },
        changeStatus() {
            this.showDialog = true
        },
        closeDialog() {
            this.showDialog = false;
            this.checkAll = false;
            this.changeMultipleStatus = [];
            this.formStatus = JSON.parse(JSON.stringify({
                reason_id: null
            }));

        }
    }
}
